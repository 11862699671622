<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('registration_date_just')">
                            <select-date v-model="formData.date" :validationError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('meeting_status')">
                            <parameter-selectbox v-model="formData.status"
                                code="alumni_meeting_status"></parameter-selectbox>
                            <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="note" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('meeting_note')">
                            <b-form-textarea v-model="formData.note" :class="errors[0] ? 'is-invalid' : ''" rows="6" />
                            <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

//Pages

// Services
import AlumniContactService from '@/services/AlumniContactService';
import AlumniService from '@/services/AlumniService';

// Others
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    props: {
        isUpdate: {
            type: Boolean
        }
    },
    components: {
        ParameterSelectbox,
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            formData: {
                date: null,
                status: null,
                note: null
            },
        }
    },
    created() {
        this.setData()
        this.ContactData()
        this.studentId = this.$route.params.id
    },
    watch: {
        isUpdate: {
            handler(value) {
                this.ContactData()
            }
        },
    },
    methods: {
        setData() {
            this.formData = {}
        },
        ContactData() {
            return AlumniService.ContactInfo(this.$route.params.id)
                .then(response => {
                    this.responseContactData = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        async saveForm() {
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {

                let formData = {
                    ...this.formData,
                    student_id: this.studentId
                }

                AlumniContactService.store(formData)
                    .then(response => {
                        this.ContactData()
                        this.$emit('updateSuccess', 'meetings')
                        this.$toast.success(this.$t('api.' + response.data.message));
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    }).finally(() => {
                        this.formProcess = false
                    })
            }
        },
    }
}
</script>
