<template>
    <div>
        <b-row>
            <b-col md="6">
                <ValidationObserver ref="formValidate">
                    <ValidationProvider name="want_degree" rules="" v-slot="{errors}">
                        <b-form-group :label="$t('do_you_think_to_do_master_in_corp')">
                            <multi-selectbox v-model="formData.want_degree"
                                :multiple="false"
                                :options="trueOrFalseFormat"
                                :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </ValidationObserver>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end">
                <b-button class="mt-4" variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

//Pages

// Services
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        MultiSelectbox
    },

    data() {
        return {
            formData: {
                want_degree: null
            },
            trueOrFalseFormat: [
                {value: 'e', text: this.$t('yes')},
                {value: 'h', text: this.$t('no')}
            ],
        }
    },
    created() {
        this.degreeInformationData()
            .then(() => {
                this.setData()
            })
    },
    methods: {
        setData(){
            this.formData = {
                want_degree: this.responseDegreeData.want_degree
            }
            this.formData.want_degree = this.responseDegreeData.want_degree == true ? 'e' : 'h'
        },
        degreeInformationData(){
            return AlumniService.degreeInformation(this.$route.params.id)
                .then(response => {
                    this.responseDegreeData  = response.data.data;
                    this.setData()
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formData.want_degree = this.formData.want_degree == 'e' ? 1 : 0
                let formData = {
                    ...this.formData
                }

                AlumniService.degreeInformationUpdate(this.$route.params.id, formData)
                .then(response => {
                    this.degreeInformationData()
                    this.$emit('updateSuccess','education_info')
                    this.$toast.success(this.$t('api.' + response.data.message));
				}).catch(e => {
					this.showErrors(e, this.$refs.formModalValidate)
					}).finally(() => {
						this.formProcess = false
					})
            }
        },
    }
}
</script>

