<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('work_start_date')">
                            <select-date v-model="formData.start_date"
                                         :validationError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="end_date" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('work_leave_date')">
                            <select-date v-model="formData.end_date"
                                         :validationError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="company" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('corporation')">
                            <b-form-input v-model="formData.company"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="department" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('corp_department')">
                            <b-form-input v-model="formData.department"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="contract_type" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('working_type')">
                            <b-form-input v-model="formData.contract_type"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="workspace_status" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('working_tract')">
                            <b-form-input v-model="formData.workspace_status"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniWorkService from '@/services/AlumniWorkService';
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },

    data() {
        return {
            formData: {
                start_date: null,
                end_date: null,
                company: null,
                department: null,
                contract_type: null,
                workspace_status: null
            }
        }
    },
    created() {
        this.setData()
        this.WorkHistoryData()
        this.studentId = this.$route.params.id
    },
    methods: {
        setData() {
            this.formData = {}
        },
        WorkHistoryData(){
            return AlumniService.WorkHistoryInfo(this.$route.params.id)
                .then(response => {
                    this.responseWorkHistoryData  = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {

                let formData = {
                    ...this.formData,
                    student_id: this.studentId
                }

                AlumniWorkService.store(formData)
                .then(response => {
                    this.WorkHistoryData()
                    this.$emit('updateSuccess','work_history')
                    this.$toast.success(this.$t('api.' + response.data.message));
				}).catch(e => {
					this.showErrors(e, this.$refs.formModalValidate)
					}).finally(() => {
						this.formProcess = false
					})
            }
        },
    }
}
</script>

