<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('personal_info')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="12">
                            <b-row>
                                <b-col cols="12" lg="4">
                                    <div class="label-as-input-div">
                                        <label>{{
                                            $t("personal_email")
                                            }}</label>
                                        <div>
                                            {{
                                                responsePersonalData.personal_email
                                            }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="4">
                                    <div class="label-as-input-div">
                                        <label>{{
                                            $t("graduate_email")
                                            }}</label>
                                        <div>
                                            {{
                                                responsePersonalData.alumni_email
                                            }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="4">
                                    <div class="label-as-input-div">
                                        <label>{{ $t("mobile_number") }}</label>
                                        <div>
                                            {{ responsePersonalData.phone }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t("birthdate") }}</label>
                                        <div>
                                            {{ responsePersonalData.birthdate }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{
                                            $t("marriage_status")
                                            }}</label>
                                        <div v-if="
                                            responsePersonalData.marital_status ==
                                            '1'
                                        ">
                                            {{ $t("evli") }}
                                        </div>
                                        <div v-else-if="
                                            responsePersonalData.marital_status ==
                                            '0'
                                        ">
                                            {{ $t("bekar") }}
                                        </div>
                                        <div v-else>
                                            {{
                                                responsePersonalData.marital_status
                                            }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{
                                            $t("children_available")
                                            }}</label>
                                        <div>
                                            {{
                                                responsePersonalData.child_status
                                            }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t("country") }}</label>
                                        <div>
                                            {{
                                                responsePersonalData.country_text
                                            }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{ $t("current_city") }}</label>
                                        <div>
                                            {{ responsePersonalData.city }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{
                                            $t("communication_city")
                                            }}</label>
                                        <div>
                                            {{
                                                responsePersonalData.contact_city
                                            }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{
                                            $t("current_district")
                                            }}</label>
                                        <div>
                                            {{ responsePersonalData.district }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{
                                            $t("communication_district")
                                            }}</label>
                                        <div>
                                            {{
                                                responsePersonalData.contact_district
                                            }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{
                                            $t("residence_address")
                                            }}</label>
                                        <div>
                                            {{ responsePersonalData.address }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <div class="label-as-input-div">
                                        <label>{{
                                            $t("communication_address_2")
                                            }}</label>
                                        <div>
                                            {{
                                                responsePersonalData.contact_address
                                            }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="12">
                                    <b-button class="mt-4" variant="primary" @click="
                                        $emit('updateClick', [
                                            'personal_info',
                                        ])
                                        ">{{ $t("edit") }}</b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniService from "@/services/AlumniService";

// Others

export default {
    props: {
        isUpdate: {
            type: Boolean,
        },
    },
    components: {},

    data() {
        return {
            responsePersonalData: {
                personal_email: "",
                alumni_email: "",
                phone: "",
                birthdate: "",
                marital_status: "",
                child_status: "",
                country: "",
                city: "",
                contact_city: "",
                district: "",
                contact_district: "",
                address: "",
                contact_address: "",
            },
        };
    },
    created() {
        this.PersonalData();
    },
    watch: {
        isUpdate: {
            handler(value) {
                this.PersonalData();
            },
        },
    },
    methods: {
        PersonalData() {
            return AlumniService.PersonalInfo(this.$route.params.id)
                .then((response) => {
                    this.responsePersonalData = response.data.data;
                })
                .catch((e) => {
                    if (e.data.message) {
                        this.$toast.error(this.$t("api." + e.data.message));
                    }
                });
        },
    },
};
</script>
