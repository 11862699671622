<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('graduate_info')" class="soft mb-5">
                    <div class="accordion" role="tablist">
                        <b-card
                            no-body
                            class="mb-1"
                            v-for="(item, index) in responseData"
                            :key="index"
                        >
                            <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                            >
                                <b-button
                                    block
                                    v-b-toggle="'accordion-' + index"
                                    style="
                                        background-color: #00b0f0;
                                        font-size: 10px;
                                    "
                                    >{{ item.faculty_name }} /
                                    {{ item.program_name }}</b-button
                                >
                            </b-card-header>
                            <b-collapse
                                :id="'accordion-' + index"
                                visible
                                role="tabpanel"
                            >
                                <b-card-body>
                                    <b-row>
                                        <b-col cols="12" lg="4">
                                            <div class="label-as-input-div">
                                                <label>{{
                                                    $t("faculty")
                                                }}</label>
                                                <div>
                                                    {{ item.faculty_name }}
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <div class="label-as-input-div">
                                                <label>{{
                                                    $t("national_number")
                                                }}</label>
                                                <div
                                                    v-if="
                                                        responsePersonalData &&
                                                        responsePersonalData.national_id
                                                    "
                                                >
                                                    {{
                                                        responsePersonalData.national_id
                                                    }}
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <div class="label-as-input-div">
                                                <label>{{
                                                    $t("graduate_semester")
                                                }}</label>
                                                <div>
                                                    {{ item.graduation_date }}
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <div class="label-as-input-div">
                                                <label>{{
                                                    $t("department")
                                                }}</label>
                                                <div>
                                                    {{ item.department_name }}
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <div class="label-as-input-div">
                                                <label>{{ $t("name") }}</label>
                                                <div
                                                    v-if="
                                                        responsePersonalData &&
                                                        responsePersonalData.name
                                                    "
                                                >
                                                    {{
                                                        responsePersonalData.name
                                                    }}
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <div class="label-as-input-div">
                                                <label>{{
                                                    $t("graduate_period")
                                                }}</label>
                                                <div>
                                                    {{
                                                        item.graduation_semester_name
                                                    }}
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <div class="label-as-input-div">
                                                <label>{{
                                                    $t("program")
                                                }}</label>
                                                <div>
                                                    {{ item.program_name }}
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <div class="label-as-input-div">
                                                <label>{{
                                                    $t("surname")
                                                }}</label>
                                                <div
                                                    v-if="
                                                        responsePersonalData &&
                                                        responsePersonalData.surname
                                                    "
                                                >
                                                    {{
                                                        responsePersonalData.surname
                                                    }}
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="4">
                                            <div class="label-as-input-div">
                                                <label>{{
                                                    $t("graduation_date")
                                                }}</label>
                                                <div>
                                                    {{ item.graduation_date }}
                                                </div>
                                            </div>
                                        </b-col>
                                        <!-- <b-col cols="12" lg="12">
                                          <b-button variant="primary" @click="$emit('updateClick',['graduate_info'])">{{ $t('edit') }}</b-button>
                                      </b-col> -->
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniService from "@/services/AlumniService";

// Others

export default {
    components: {},

    data() {
        return {
            responsePersonalData: null,
            responseData: null,
        };
    },
    created() {
        this.getData(), this.PersonalData();
    },
    methods: {
        getData() {
            return AlumniService.get(this.$route.params.id)
                .then((response) => {
                    this.responseData = response.data.data;
                })
                .catch((e) => {
                    if (e.data.message) {
                        this.$toast.error(this.$t("api." + e.data.message));
                    }
                });
        },
        PersonalData() {
            return AlumniService.PersonalInfo(this.$route.params.id)
                .then((response) => {
                    this.responsePersonalData = response.data.data;
                })
                .catch((e) => {
                    if (e.data.message) {
                        this.$toast.error(this.$t("api." + e.data.message));
                    }
                });
        },
    },
};
</script>
