<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('contact_confirmations')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('approve_sms') }}</label>
                                <div v-if="responseCommunicationData.sms_approve === false">{{ $t('no') }}</div>
                                <div v-else-if="responseCommunicationData.sms_approve === true">{{ $t('yes') }}</div>
                                <div v-else>{{ responseCommunicationData.sms_approve }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('approve_email') }}</label>
                                <div v-if="responseCommunicationData.email_approve === false">{{ $t('no') }}</div>
                                <div v-else-if="responseCommunicationData.email_approve === true">{{ $t('yes') }}</div>
                                <div v-else>{{ responseCommunicationData.email_approve }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('approve_call') }}</label>
                                <div v-if="responseCommunicationData.phone_approve === false">{{ $t('no') }}</div>
                                <div v-else-if="responseCommunicationData.phone_approve === true">{{ $t('yes') }}</div>
                                <div v-else>{{ responseCommunicationData.phone_approve }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <b-button class="mt-4" variant="primary" @click="$emit('updateClick',['contact_confirmations'])">{{ $t('edit') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniService from '@/services/AlumniService';

// Others

export default {
    props: {
        isUpdate: {
            type: Boolean
        }
    },
    components: {

    },

    data() {
        return {
            responseCommunicationData: {
                sms_approve: null,
                email_approve: null,
                phone_approve: null
            }

        }
    },
    created() {
        this.CommunicationData()

    },   
    watch: {
        isUpdate: {
            handler(value){
                this.CommunicationData()
            }
        }
    },
    methods: {
        CommunicationData(){
            return AlumniService.CommunicationInfo(this.$route.params.id)
                .then(response => {
                    this.responseCommunicationData  = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
    }
}
</script>

