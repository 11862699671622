<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="working_status" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('work_status')">
                            <multi-selectbox v-model="responseWorkingData.working_status" :multiple="false"
                                :options="workStatusOptions" :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="not_working_reason" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('not_working_reason')">
                            <parameter-selectbox v-model="responseWorkingData.not_working_reason"
                                code="alumni_reason_for_not_working"
                                :disabled="responseWorkingData.working_status === 'working'"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="working_organisation" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('job_corporation')">
                            <b-form-input v-model="responseWorkingData.working_organisation"
                                :placeholder="$t('job_corporation')" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="working_sector" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('job_sector')">
                            <parameter-selectbox v-model="responseWorkingData.working_sector"
                                code="alumni_sector_of_work"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="working_position" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('positional_status')">
                            <parameter-selectbox v-model="responseWorkingData.working_position"
                                code="alumni_position"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="working_position_detail" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('position_detail')">
                            <b-form-input v-model="responseWorkingData.working_position_detail"
                                :placeholder="$t('position_detail')" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{
                        $t("save")
                    }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

//Pages

// Services
import AlumniService from "@/services/AlumniService";

// Others
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        ParameterSelectbox,
        MultiSelectbox,
    },

    data() {
        return {
            responseWorkingData: {
                working_status: null,
                not_working_reason: null,
                working_organisation: null,
                working_sector: null,
                working_position: null,
                working_position_detail: null,
            },
            workStatusOptions: [
                {
                    text: this.$t("working"),
                    value: "working",
                },
                {
                    text: this.$t("not_working"),
                    value: "not_working",
                },
            ],
        };
    },
    created() {
        this.setData();
        this.WorkingData();
        this.studentId = this.$route.params.id;
    },
    methods: {
        setData() {
            this.responseWorkingData = {};
        },
        WorkingData() {
            return AlumniService.WorkingInfo(this.$route.params.id)
                .then((response) => {
                    this.responseWorkingData = response.data.data;
                })
                .catch((e) => {
                    if (e.data.message) {
                        this.$toast.error(this.$t("api." + e.data.message));
                    }
                });
        },
        async saveForm() {
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                let responseWorkingData = {
                    ...this.responseWorkingData,
                };

                AlumniService.workingUpdate(this.studentId, responseWorkingData)
                    .then((response) => {
                        this.WorkingData();
                        this.$emit("updateSuccess", "job_information");
                        this.$toast.success(this.$t("api." + response.data.message));
                    })
                    .catch((e) => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formProcess = false;
                    });
            }
        },
    },
};
</script>
