<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('education_info')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="12">
                            <b-table :empty-filtered-text="$t('no_result')" :empty-text="$t('no_result')" bordered
                                responsive :items="mappedEducationInfo" :fields="educationFields" show-empty
                                class="mb-4 table-dropdown no-scrollbar border rounded">
                                <template #cell(buttons)="row">
                                    <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                        <template #button-content>
                                            <i class="ri-more-fill"></i>
                                        </template>
                                        <div id="dropdownListHead">
                                            <!-- <b-dropdown-item @click="$emit('updateClick',['education_info','edit'],row.item.id)" v-if="checkPermission('documentrequest_sendebys')">
                                                <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                            </b-dropdown-item> -->
                                            <b-dropdown-item @click="deleteEducationInfo(row.item.id)"
                                                v-if="checkPermission('documentrequest_sendebys')">
                                                <i
                                                    class="ri-delete-bin-line align-middle top-minus-1 mr-3 text-muted"></i>
                                                {{ $t('delete') }}
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                </template>
                                <template #head(buttons)="row">
                                    <b-button variant="primary"
                                        @click="$emit('updateClick', ['education_info', 'add'])"><i
                                            class="ri-add-line"></i> {{ $t('add') }}</b-button>
                                </template>
                            </b-table>
                        </b-col>
                        <b-col cols="12" lg="12" class="mt-5">
                            <div class="label-as-input-div">
                                <label>{{ $t('do_you_think_to_do_master_in_corp') }}</label>
                                <div v-if="responseDegreeData.want_degree === false">{{ $t('no') }}</div>
                                <div v-else-if="responseDegreeData.want_degree === true">{{ $t('yes') }}</div>
                                <div v-else>{{ responseDegreeData.want_degree }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <b-button class="mt-1" variant="primary"
                                @click="$emit('updateClick', ['education_info'])">{{
                                    $t('edit')
                                }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniEducationService from '@/services/AlumniEducationService';
import AlumniService from '@/services/AlumniService';

// Others

export default {
    props: {
        isUpdate: {
            type: Boolean
        }
    },
    components: {
    },

    data() {
        return {
            responseEducationData: [],
            responseDegreeData: {},
            educationFields: [
                {
                    key: 'buttons',
                    label: '',
                },
                {
                    key: 'level',
                    label: this.$t('education_status'),
                },
                {
                    key: 'school_name',
                    label: this.$t('school') + ' / ' + this.$t('university'),
                },
                {
                    key: 'program',
                    label: this.$t('field_program'),
                },
                {
                    key: 'graduate_date',
                    label: this.$t('graduate_date'),
                },
                {
                    key: 'diploma_number',
                    label: this.$t('diploma_number'),
                },
                {
                    key: 'diploma_point',
                    label: this.$t('diploma_point'),
                }
            ]
        }
    },
    watch: {
        isUpdate: {
            handler(value) {
                this.EducationData(), this.degreeInformationData()
            }
        }
    },
    computed: {
        mappedEducationInfo() {
            return this.responseEducationData.map(item => {
                let translatedLevel = '';
                switch (item.level) {
                    case 'high_school':
                        translatedLevel = this.$t('high_school')
                        break;
                    case 'associate_degree':
                        translatedLevel = this.$t('program_level_ol')
                        break;
                    case 'undergraduate':
                        translatedLevel = this.$t('program_level_l')
                        break;
                    case 'graduate':
                        translatedLevel = this.$t('program_level_yl')
                        break;
                    case 'doctorate':
                        translatedLevel = this.$t('program_level_d')
                        break;
                    default:
                        translatedLevel = item.level;
                }
                return {
                    ...item,
                    level: translatedLevel
                }
            });
        }
    },
    created() {
        this.studentId = this.$route.params.id
        this.EducationData(),
            this.degreeInformationData()
    },
    methods: {
        EducationData() {
            return AlumniService.EducationInfo(this.$route.params.id)
                .then(response => {
                    this.responseEducationData = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        degreeInformationData() {
            return AlumniService.degreeInformation(this.$route.params.id)
                .then(response => {
                    this.responseDegreeData = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        deleteEducationInfo(id) {
            this.deleteModal(() => {
                AlumniEducationService.del(id)
                    .then(response => {
                        this.EducationData()
                        this.$emit('updateSuccess', 'education_info')
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            });
        },
    }
}
</script>
